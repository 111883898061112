import React from "react";
import * as styles from "./styles.module.scss";
import { Link } from "gatsby-plugin-intl";
import { connect } from "react-redux";

const BackgroundBanner = ({ imgDesktop, imgMobile, subtitle, title, text, linkTo, linkText, external, s }) => {
	return (
		<div className={styles.backgroundWrapper}>
			<picture>
				<source srcSet={imgDesktop} media="(min-width: 992px)" />
				<img srcSet={imgMobile} />
			</picture>
			<div className={styles.wrapperText}>
				<div className={styles.textBox}>
					<span>{subtitle}</span>
					<h3>{title}</h3>
					<p>{text}</p>
					{external && (
						<a href={linkTo} className="redButton" target="_blank">
							{linkText}
						</a>
					)}
					{!external && (
						<Link to={linkTo} className="redButton">
							{linkText}
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(BackgroundBanner);
