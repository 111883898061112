import React from "react";
import { SpecialBulletsWrapper, BulletsWrapper, BulletItems } from "./styled";
import { GlobalInnerContent } from "../../../assets/styles/commonStyles";
import { ReactSVG } from "react-svg";

const SpecialBullets = ({ items, upperCase }) => {
	return (
		<SpecialBulletsWrapper>
			<GlobalInnerContent>
				<BulletsWrapper>
					{items?.map((item, index) => (
						<BulletItems key={index} upperCase={upperCase}>
							{item.icon ? (
								<ReactSVG src={item.icon} wrapper="span" />
							) : (
								<span className="number">{index + 1}.</span>
							)}
							<h4>{item.title}</h4>
							{item.text && <p>{item.text}</p>}
						</BulletItems>
					))}
				</BulletsWrapper>
			</GlobalInnerContent>
		</SpecialBulletsWrapper>
	);
};

export default SpecialBullets;
