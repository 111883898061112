import styled from "styled-components";
import { colors } from "../../../assets/styles/colors";
import { mediaQueryMax } from "../../../assets/styles/size";

export const TestimonialWrapper = styled.section`
	background-image: url("/img/bg/testimonials.jpg");
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	height: 475px;
	display: flex;
	align-items: center;
	position: relative;
	@media ${mediaQueryMax.lg0} {
		min-height: 300px;
		height: auto;
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background: ${colors.blue};
		opacity: 0.96;
	}

	.slick-slider {
		z-index: 2;

		.slick-dots {
			li {
				margin: 0;

				button {
					display: flex;
					align-items: center;
					justify-content: center;

					&:before {
						content: "";
						position: relative;
						opacity: 1;
						width: 10px;
						height: 10px;
						border: 1px solid ${colors.white};
						border-radius: 99em;
						transition: 0.3s ease-in-out;
					}
				}

				&.slick-active {
					button {
						&:before {
							background-color: ${colors.white};
						}
					}
				}
			}
		}
	}
`;

export const TestimonialText = styled.div`
	width: auto !important;
	//max-width: 420px;
	max-width: 620px;
	margin: 0 auto;
	display: flex !important;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	span {
		font-family: "Montserrat", sans-serif;
		font-weight: 700;
		font-size: 135px;
		line-height: 100px;
		color: ${colors.white};
		opacity: 0.08;
		margin: 0 0 -30px;
	}
	h5 {
		color: ${colors.ltblue03};
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 25px;
		border-bottom: none;
	}
	p {
		color: ${colors.white};
		font-size: 20px;
		line-height: 32px;
		text-align: center;
		font-weight: 400;
		margin-bottom: 20px;
		@media ${mediaQueryMax.lg0} {
			font-size: 18px;
		}
	}
`;
