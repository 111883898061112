import React from "react";
import { connect } from "react-redux";
import { ImageTwoColWrapper, TwoColWrapper, TextCol, Text, ImgCol } from "./styled";
import { GlobalInnerContent, StyledButton } from "../../../assets/styles/commonStyles";
import { Link } from "gatsby-plugin-intl";

const ImageTwoCol = (props) => {
	const {
		full = false,
		position = "left",
		imgDesktop,
		imgMobile,
		title,
		text,
		linkTo,
		linkText,
		external,
		action,
		buttonClassName,
		s,
	} = props;
	return (
		<ImageTwoColWrapper full={full}>
			<GlobalInnerContent>
				<TwoColWrapper position={position}>
					<TextCol>
						{title && <h2>{title}</h2>}
						{text && <Text dangerouslySetInnerHTML={{ __html: text }} />}
						<StyledButton>
							{action && (
								<button onClick={action} className={buttonClassName}>
									{linkText}
								</button>
							)}
							{!action && external && (
								<a href={linkTo} className={buttonClassName}>
									{linkText}
								</a>
							)}
							{!action && !external && (
								<Link to={linkTo} className={buttonClassName}>
									{linkText}
								</Link>
							)}
						</StyledButton>
					</TextCol>
					<ImgCol imgMobile={imgMobile}>
						<figure>
							<img className="desktop" src={imgDesktop} alt={title} />
							{imgMobile && <img className="mobile" src={imgMobile} alt={title} />}
						</figure>
					</ImgCol>
				</TwoColWrapper>
			</GlobalInnerContent>
		</ImageTwoColWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(ImageTwoCol);
