import React from "react";
import * as styles from "./styles.module.scss";
import { Link } from "gatsby-plugin-intl";
import { ReactSVG } from "react-svg";
import { connect } from "react-redux";

const Lodge = ({ image, title, text, sea, discount, linkTo, linkText, s, linkClassName }) => {
	return (
		<Link to={linkTo} className={`${styles.lodgeItem} ${linkClassName}`}>
			<div className={styles.wrapperImg}>
				{sea && <p>{sea}</p>}
				{discount && <span>{discount}</span>}
				<img src={image} />
			</div>
			<div className={styles.lodgeDescription}>
				<h4>{title}</h4>
				<p>{text}</p>
				<span>
					{linkText}
					<ReactSVG src="/img/arrow.svg" wrapper="span" />
				</span>
			</div>
		</Link>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(Lodge);
