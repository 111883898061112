import React from "react";
import * as styles from "./styles.module.scss";
import { Link } from "gatsby-plugin-intl";
import { ReactSVG } from "react-svg";
import { connect } from "react-redux";

const BannerHome = ({ imgDesktop, imgMobile, title, text, linkTo, linkText, external, action, s }) => {
	const getLink = (contents) => {
		let link;
		if (action) {
			link = (
				<a onClick={action} className={styles.wrapperImg}>
					{contents}
				</a>
			);
		} else {
			link = external ? (
				<a href={linkTo} className={styles.wrapperImg}>
					{contents}
				</a>
			) : (
				<Link to={linkTo} className={styles.wrapperImg}>
					{contents}
				</Link>
			);
		}
		return link;
	};
	return getLink(
		<>
			<picture>
				<source srcSet={imgDesktop} media="(min-width: 992px)" />
				<img srcSet={imgMobile} />
			</picture>
			<div className={styles.bannerText}>
				<h3>{title}</h3>
				<p>{text}</p>
				<span>
					{linkText}
					<ReactSVG src="/img/arrow.svg" wrapper="span" />
				</span>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(BannerHome);
