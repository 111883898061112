import { Link } from "gatsby-plugin-intl";
import styled from "styled-components";
import { colors } from "./colors";
import { mediaQueryMax, sizes } from "./size";
// import { mediaQueryMin } from "./size";

export const GoBackButton = styled(Link)`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	cursor: pointer;
	padding: 14px;
	span {
		width: 12px;
		margin-right: 12px;
		svg {
			width: 100%;
			height: auto;
		}
	}
	p {
		margin: 0;
		font-size: 15px;
		line-height: 1;
		color: ${colors.blackColor01};
	}
	&:hover {
		p {
			text-decoration: underline;
		}
	}
`;

export const GlobalInnerContent = styled.div`
	max-width: calc(${sizes.xl} + 180px);
	width: 100%;
	padding: 0 90px;
	margin: 0 auto;
	position: relative;
	//overflow: hidden;
	@media ${mediaQueryMax.lg0} {
		padding: 0 25px;
		margin-bottom: 30px;
	}
`;

export const StyledButton = styled.div`
	${(props) => props.center && "text-align: center;"}
	a,
	button {
		background-color: ${colors.red};
		padding: 15px 30px;
		font-size: 20px;
		min-width: 210px;
		margin: 0;
		border: none;
		display: inline-block;
		color: white;
		font-weight: bold;
		font-family: Ubuntu, sans-serif;
		cursor: pointer;
		text-align: center;
	}
`;
