import React, { useEffect, useRef } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Footer from "../components/footer";
import BodyClassName from "react-body-classname";
import { ReactSVG } from "react-svg";
import { connect } from "react-redux";
import {
	appRedirect,
	appUIPopupLoginShow,
	appUIPopupTypeformShow,
	anunciosHomeInit,
	anunciosHomeGet,
	appUIErrorsShow,
} from "../redux/app/actions";
import { useIntl, Link, navigate } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BannerHome from "../components/BannerHome";
import Grid from "../components/Grid";
import BackgroundBanner from "../components/BackgroundBanner";
import Tools from "../classes/tools";
import Geosuggest from "react-geosuggest";
import SliderCustom from "../components/common/SliderCustom";
import SliderTestimonials from "../components/common/SliderTestimonials";
import SpecialBullets from "../components/common/SpecialBullets";
import ImageTwoCol from "../components/common/ImageTwoCol";
import ImageList from "../components/common/ImageList";

const IndexPage = ({
	authResolved,
	loggedIn,
	usuario,
	appRedirect,
	appUIPopupLoginShow,
	appUIPopupTypeformShow,
	appUIErrorsShow,
	geo,
	anunciosHome,
	home_areas_banners,
	home_areas_sliders,
	home_banner_imagen_desktop,
	home_banner_imagen_mobile,
	home_banner_link,
	home_banner_link_external,
	anunciosHomeInit,
	anunciosHomeGet,
	bootLoaded,
	areas,
	s,
}) => {
	const intl = useIntl();

	const query = useStaticQuery(
		graphql`
			{
				site {
					siteMetadata {
						fase
					}
				}
				homeImage: file(relativePath: { eq: "home-01.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				homeDesktopImage: file(relativePath: { eq: "home-desktop-01.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				homeImage2: file(relativePath: { eq: "home-02.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				homeImage2Desktop: file(relativePath: { eq: "home-desktop-02.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				homeImage3: file(relativePath: { eq: "home-03.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				homeImage3Desktop: file(relativePath: { eq: "home-desktop-03.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				homeImage4: file(relativePath: { eq: "home-04.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				homeImage4Desktop: file(relativePath: { eq: "home-desktop-04.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				medias: allMarkdownRemark(
					filter: { frontmatter: { type: { eq: "media" } } }
					sort: { fields: fileAbsolutePath }
				) {
					nodes {
						frontmatter {
							language
							title
							imagen
							link
							url
						}
						html
					}
					totalCount
				}
				hoteles: allInternalHoteles(filter: { alternative_id: { ne: null } }) {
					nodes {
						name
						imagenes {
							url
						}
						intl {
							title {
								es
								en
							}
							excerpt {
								es
								en
							}
						}
					}
				}
			}
		`
	);

	// const { fase } = query.site.siteMetadata;
	// const { nodes: medias } = query.medias;
	const { nodes: hoteles } = query.hoteles;

	let bannerHomeSlider = useRef(null);

	const bannerHomeDetails = [
		{
			imgDesktop: "/img/ad1-desktop.png",
			imgMobile: "/img/ad1-mobile.png",
			title: s.home?.banner_title,
			text: s.home?.banner_text,
			action: () => {
				appUIPopupTypeformShow("asesoramiento");
			},
			linkText: s.home?.banner_link,
			external: false,
		},
	];

	let backgroundSlider = useRef(null);

	const backgroundSliderDetails = [
		{
			imgDesktop: "/img/experiencias1-desktop.jpg",
			imgMobile: "/img/experiencias1-mobile.jpg",
			subtitle: "Alojamientos",
			title: "Disfruta de Zarautz en Homey Apartamentos",
			text:
				"Situado muy próximo a la playa, Homey apartamentos te ofrece una estancia con todas las comodidades para disfrutar de tus vacaciones y de un pueblo 100% surfero.",
			linkTo: "http://www.homeyapartamentos.com/index.php/es/",
			linkText: "Reservar",
			external: true,
		},
		{
			imgDesktop: "/img/experiencias2-desktop.jpg",
			imgMobile: "/img/experiencias2-mobile.jpg",
			subtitle: "Experiencias",
			title: "Redondea tu viaje con Salty Caravans",
			text:
				"¿Quieres surfear al amanecer o relajarte en las aguas termales? ¡Salty Campers lo hace posible! Con nuestra Volkswagen T3 original, experimentarás la mejor escapada en la impresionante naturaleza de Cantabria.",
			linkTo: "https://saltycampers.com/es_index.html",
			linkText: "Saber más",
			external: true,
		},
		{
			imgDesktop: "/img/experiencias3-desktop.jpg",
			imgMobile: "/img/experiencias3-mobile.jpg",
			subtitle: "Neoprenos sostenibles",
			title: "Conoce TURTL PROJECT",
			text:
				"Turtle project, marca especializada en productos sostenibles para los deportes acuáticos, lanza su neopreno sostenible Galapagos. ¡Date prisa y se de los primeros en tener uno!",
			linkTo:
				"https://www.kickstarter.com/projects/turtlproject/galapagos-the-ultimate-wetsuit-turtl-project?ref=7l1roe",
			linkText: "Saber más",
			external: true,
		},
		{
			imgDesktop: "/img/experiencias4-desktop.jpg",
			imgMobile: "/img/experiencias4-mobile.jpg",
			subtitle: "App",
			title: "Todas las previsiones y webcams en una misma app",
			text:
				"¿Estás cansado de buscar y comparar información sobre el estado del mar? Selecciona tu spot y Party Wave encuentra por ti las mejores previsiones, webcams, tiendas, escuelas y shapers existentes.",
			linkTo: "http://www.partywave.es/",
			linkText: "Descarga la app",
			external: true,
		},
	];

	const testimonialsSliderDetails = [
		{
			icon: "“",
			name: "Luis Reismann",
			text: "Me encantaría que esta iniciativa se pudiese hacer en todo el mundo.",
		},
		{
			icon: "“",
			name: "Redbull",
			text: "No seas buitre, ¡comparte tu tabla!",
		},
		{
			icon: "“",
			name: "Paola Guillén",
			text: "Se lo recomiendo a todos mis amigos, te ganas un dinerillo extra y conoces mucha gente nueva.",
		},
		{
			icon: "“",
			name: "Noticias de Guipúzcoa",
			text: "El “Wallapop” de las olas: Tablas de surf a la carta y por horas.",
		},
	];

	const whoweareDetails = [
		{
			title: Tools.convertString(s.home?.bullet_1_title),
			text: Tools.convertString(s.home?.bullet_1_text),
		},
		{
			title: Tools.convertString(s.home?.bullet_2_title),
			text: Tools.convertString(s.home?.bullet_2_text),
		},
		{
			title: Tools.convertString(s.home?.bullet_3_title),
			text: Tools.convertString(s.home?.bullet_3_text),
		},
	];

	const tipSliderDetails = [
		{
			image: "/img/planes-barcelona.png",
			title: "Surfing en Barcelona",
			text:
				"Consejos, historia, sus mejores playas, dónde alquilar tablas, escuelas, fiestas… todo lo que necesitas saber sobre el surf en Barcelona.",
			sea: "Mediterráneo",
			linkTo: "/paginas/viajes-surfing-barcelona",
			linkText: "Saber más",
			linkClassName: "contenidos-home",
		},
		{
			image: "/img/planes-norte.jpg",
			title: "Surfing en el norte",
			text: "Las mejores playas surferas de la España peninsular están, sin duda, en el norte del país.",
			sea: "Norte",
			linkTo: "/paginas/viajes-de-surfing-el-norte-de-espana",
			linkText: "Saber más",
			linkClassName: "contenidos-home",
		},
		{
			image: "/img/planes-cadiz.jpg",
			title: "Surfing en Cádiz y Málaga",
			text: "No. Tarifa no es la Meca del surf en Cádiz. Conoce los principales spots de esta zona.",
			sea: "Cádiz",
			linkTo: "/paginas/viajes-de-surfing-cadiz-y-malaga",
			linkText: "Saber más",
			linkClassName: "contenidos-home",
		},
		{
			image: "/img/planes-mediterraneo.jpg",
			title: "Surfing en el Mediterráneo",
			text: "Conoce los mejores spots del Mediterráneo y organiza tu viaje de surfing.",
			sea: "Mediterráneo",
			linkTo: "/paginas/viajes-surfing-mediterraneo",
			linkText: "Saber más",
			linkClassName: "contenidos-home",
		},
	];

	const crearAnuncio = (e) => {
		e.preventDefault();
		if (authResolved && loggedIn) appRedirect({ to: usuario.completo ? "/crearanuncio" : "/editarcuenta" });
		else appUIPopupLoginShow();
	};

	const geoSuggestRef = useRef(null);

	const onSuggestSelect = (suggest) => {
		if (suggest) {
			const { lat, lng } = suggest.location;
			const { description } = suggest;
			navigate(`/anuncios/mode/geo/lat/${lat}/long/${lng}/geo_descripcion/${description}/orden/distancia`);
		}
	};

	const goAnuncios = () => {
		if (!geo.lat || !geo.long || !geo.descripcion) {
			appUIErrorsShow(["Elige una ubicación para encontrar tu tabla."]);
		} else {
			navigate(
				`/anuncios/mode/geo/lat/${geo.lat}/long/${geo.long}/geo_descripcion/${geo.descripcion}/orden/distancia`
			);
		}
	};

	useEffect(() => {
		if (bootLoaded) {
			anunciosHomeInit();
			home_areas_sliders.forEach((a) => {
				anunciosHomeGet(intl.locale, { area: a.id, mode: "area" });
			});
		}
	}, [bootLoaded]);

	// const [slidersAnuncios, setSlidersAnuncios] = useState([]);

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="home">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s?.home?.title)} />
					<main className="page">
						<section>
							{/* BANNER PRINCIPAL HOME */}
							<div className="mainBanner">
								<div className="wrapper">
									<form
										onSubmit={(e) => {
											e.preventDefault();
										}}
									>
										<h3
											dangerouslySetInnerHTML={{
												__html: Tools.convertString(s.home?.searchlocation_title),
											}}
										/>
										{/* <h4
											dangerouslySetInnerHTML={{
												__html: s.home?.searchlocation_subtitle" }),
											}}
										/> */}
										<div className="formWrapper">
											<ReactSVG src="/img/location.svg" />
											<Geosuggest
												ref={geoSuggestRef}
												placeholder={Tools.convertString(s.home?.searchlocation_placeholder)}
												initialValue={geo.descripcion}
												onSuggestSelect={onSuggestSelect}
												autoActivateFirstSuggest={true}
												autoCapitalize="true"
												autoComplete="new-password"
												autoCorrect="false"
												autoSave="false"
											/>
										</div>
										<button className="button redButton" onClick={goAnuncios}>
											{Tools.convertString(s.home?.searchlocation_button)}
										</button>
									</form>
								</div>
							</div>
							{/* BANNER PRINCIPAL HOME */}

							{/* LITTLE BLUE BOX SPONSOR WITH IMG */}
							<div className="sponsors">
								<div className="wrapper">
									<p>Sponsored by</p>
									<div className="logos">
										{/* <img className="voited" src="/img/voited-logo.png" /> */}
										<a href="https://crowdland.ad" target="_blank">
											<ReactSVG style={{ width: "40px" }} src="/img/logo-crowdland.svg" />
										</a>
									</div>
								</div>
							</div>
							{/* LITTLE BLUE BOX SPONSOR WITH IMG */}

							{/* SLIDER 1 */}
							{anunciosHome &&
								anunciosHome.length > 0 &&
								anunciosHome[0].anuncios &&
								anunciosHome[0].anuncios.length > 0 && (
									<SliderCustom
										title={`${Tools.convertString(s.home?.tablas_en)} ${
											Tools.findAreaById(anunciosHome[0].area, areas).title
										}`}
										linkText={`${Tools.convertString(s.home?.ver_tablas_en)} ${
											Tools.findAreaById(anunciosHome[0].area, areas).title
										}`}
										linkUrl={`/anuncios/${Tools.buildUrlPath({
											mode: "area",
											area: Tools.findAreaById(anunciosHome[0].area, areas).id,
										})}`}
										items={anunciosHome[0].anuncios}
										typeContent="board"
									/>
								)}
							{/* SLIDER 1 */}

							{/* BANNER HOME */}
							{/* <div className="bannerHome">
								<div className="wrapper">
									<Slider
										ref={(c) => (bannerHomeSlider = c)}
										slidesToShow={1}
										slidesToScroll={1}
										dots={false}
										arrows={false}
										infinite={true}
										autoplay={true}
										autoplaySpeed={3000}
										speed={500}
										fade={true}
										cssEase="linear"
									>
										{bannerHomeDetails.map((bannerHomeDetail, index) => (
											<BannerHome
												key={index}
												imgDesktop={bannerHomeDetail.imgDesktop}
												imgMobile={bannerHomeDetail.imgMobile}
												title={bannerHomeDetail.title}
												text={bannerHomeDetail.text}
												linkTo={bannerHomeDetail.linkTo}
												linkText={bannerHomeDetail.linkText}
												external={bannerHomeDetail.external}
												action={bannerHomeDetail.action}
											/>
										))}
									</Slider>
								</div>
							</div> */}
							{/* BANNER HOME */}

							{/* NUEVO BANNER HOME */}
							{bootLoaded && (
								<ImageTwoCol
									imgDesktop={home_banner_imagen_desktop.url ? home_banner_imagen_desktop.url : ""}
									imgMobile={home_banner_imagen_mobile.url ? home_banner_imagen_mobile.url : ""}
									title={Tools.convertString(s.home?.banner_title)}
									text={Tools.convertString(s.home?.banner_text)}
									linkText={Tools.convertString(s.home?.banner_link)}
									external={home_banner_link_external}
									action={
										home_banner_link === ""
											? () => {
													appUIPopupTypeformShow("asesoramiento");
											  }
											: null
									}
									linkTo={home_banner_link}
									buttonClassName="banner_home"
								/>
							)}
							{/* NUEVO BANNER HOME */}

							{/* SLIDER 2 */}
							{anunciosHome &&
								anunciosHome.length > 1 &&
								anunciosHome[1].anuncios &&
								anunciosHome[1].anuncios.length > 0 && (
									<SliderCustom
										title={`${Tools.convertString(s.home?.tablas_en)} ${
											Tools.findAreaById(anunciosHome[1].area, areas).title
										}`}
										linkText={`${Tools.convertString(s.home?.ver_tablas_en)} ${
											Tools.findAreaById(anunciosHome[1].area, areas).title
										}`}
										linkUrl={`/anuncios/${Tools.buildUrlPath({
											mode: "area",
											area: Tools.findAreaById(anunciosHome[1].area, areas).id,
										})}`}
										items={anunciosHome[1].anuncios}
										typeContent="board"
									/>
								)}
							{/* SLIDER 2 */}

							{/* SLIDER 3 */}
							{anunciosHome &&
								anunciosHome.length > 2 &&
								anunciosHome[2].anuncios &&
								anunciosHome[2].anuncios.length > 0 && (
									<SliderCustom
										title={`${Tools.convertString(s.home?.tablas_en)} ${
											Tools.findAreaById(anunciosHome[2].area, areas).title
										}`}
										linkText={`${Tools.convertString(s.home?.ver_tablas_en)} ${
											Tools.findAreaById(anunciosHome[2].area, areas).title
										}`}
										linkUrl={`/anuncios/${Tools.buildUrlPath({
											mode: "area",
											area: Tools.findAreaById(anunciosHome[2].area, areas).id,
										})}`}
										items={anunciosHome[2].anuncios}
										typeContent="board"
									/>
								)}
							{/* SLIDER 3 */}

							{/* BUSCA POR ZONAS */}
							{home_areas_banners && (
								<div className="searchByArea">
									<div className="wrapper">
										<div className="wrapperText">
											<h3>{Tools.convertString(s.home?.zonas_seleccionadas_title)}</h3>
											<p>{Tools.convertString(s.home?.zonas_seleccionadas_text)}</p>
										</div>
										<div className="gridImages">
											{home_areas_banners.map((area, index) => (
												<Grid
													key={index}
													image={area.imagen?.url}
													title={area.title}
													text={Tools.convertString(s.home?.ver_tablas)}
													link={`/anuncios/${Tools.buildUrlPath({
														mode: "area",
														area: area.id,
													})}`}
													linkClassName="banner_lugares"
												/>
											))}
										</div>
									</div>
								</div>
							)}
							{/* BUSCA POR ZONAS */}

							{/* WHAT TO DO */}
							{tipSliderDetails && (
								<SliderCustom
									title={Tools.convertString(s.home?.consejos_title)}
									items={tipSliderDetails}
									typeContent="lodge"
									bgColor="#f1f1f1"
									paddingTop={80}
									paddingBottom={100}
								/>
							)}
							{/* WHAT TO DO */}

							{/* SLIDER BACKGROUND */}
							{/* <div className="backgroundSlider">
								<Slider
									ref={(c) => (backgroundSlider = c)}
									slidesToShow={1}
									slidesToScroll={1}
									dots={false}
									arrows={false}
									infinite={true}
									autoplay={true}
									autoplaySpeed={3000}
									speed={500}
									fade={true}
									cssEase="linear"
								>
									{backgroundSliderDetails.map((backgroundSliderDetail, index) => (
										<BackgroundBanner
											imgDesktop={backgroundSliderDetail.imgDesktop}
											imgMobile={backgroundSliderDetail.imgMobile}
											subtitle={backgroundSliderDetail.subtitle}
											title={backgroundSliderDetail.title}
											text={backgroundSliderDetail.text}
											linkTo={backgroundSliderDetail.linkTo}
											linkText={backgroundSliderDetail.linkText}
											external={backgroundSliderDetail.external}
										/>
									))}
								</Slider>
								<div className="media-navigation">
									<button onClick={() => backgroundSlider.slickPrev()}>
										<span className="arrow left">
											<img srcSet="/img/arrow-blue-bg.svg" />
										</span>
									</button>
									<button onClick={() => backgroundSlider.slickNext()}>
										<span className="arrow right">
											<img srcSet="/img/arrow-blue-bg.svg" />
										</span>
									</button>
								</div>
							</div> */}
							{/* SLIDER BACKGROUND */}

							{/* SLIDER HOTELES/LODGE */}
							{/* {hoteles && (
								<SliderCustom
									title={Tools.convertString(s.home?.alojamientos_title)}
									text={Tools.convertString(s.home?.alojamientos_text)}
									items={hoteles}
									typeContent="hotel"
								/>
							)} */}
							{/* SLIDER HOTELES/LODGE */}

							{/* TESTIMONIALS */}
							<SliderTestimonials items={testimonialsSliderDetails} />
							{/* TESTIMONIALS */}

							{/* HOW IT WORKS */}
							<div className="howItWorks">
								<div className="wrapper">
									<div className="left">
										<div className="grayBg">
											<ReactSVG src="/img/magnifier-how.svg" wrapper="span" />
											<h2 className="prequel-font">{Tools.convertString(s.home?.como_funciona_title)}</h2>
										</div>
										<div className="imgBg"></div>
									</div>
									<div className="right">
										<h3>{Tools.convertString(s.home?.como_funciona_subtitle)}</h3>
										<p>{Tools.convertString(s.home?.como_funciona_text)}</p>
										<Link to="/porquecompartir" className="redButton">
											{Tools.convertString(s.home?.como_funciona_link)}
										</Link>
									</div>
								</div>
							</div>
							{/* HOW IT WORKS */}

							{/* WHO WE ARE */}
							<SpecialBullets items={whoweareDetails} upperCase={true} />
							{/* WHO WE ARE */}

							{/* LOGOS */}
							<ImageList
								items={[
									{
										img: "/img/logos/logo-redbull.png",
										url: "https://www.redbull.com/es-es/surf-nace-share-your-board-itw",
									},
									{
										img: "/img/logos/logo-surferule.png",
										url: "https://www.surferrule.com/share-your-board-plataforma-colaborativa/",
									},
									{
										img: "/img/logos/logo-surf3o.png",
										url: "https://www.surf30.net/2020/07/share-your-board-lanza-su-web-para.html",
									},
									{
										img: "/img/logos/logo-3sesenta.png",
										url: "https://www.3sesenta.com/share-your-board-marketplace-de-tablas-de-surf/",
									},
									{
										img: "/img/logos/logo-as.png",
										url: "https://as.com/deportes_accion/2021/06/15/surf/1623749521_576212.html",
									},
								]}
							/>
						</section>
					</main>
					<Footer />
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	const { resolved: authResolved, loggedIn, usuario } = state.auth;
	const { geo, anunciosHome } = state.app;
	const {
		home_areas_banners,
		home_areas_sliders,
		home_banner_imagen_desktop,
		home_banner_imagen_mobile,
		home_banner_link,
		home_banner_link_external,
		loaded: bootLoaded,
		areas,
	} = state.app.boot;
	return {
		authResolved,
		loggedIn,
		usuario,
		geo,
		anunciosHome,
		home_areas_banners,
		home_areas_sliders,
		home_banner_imagen_desktop,
		home_banner_imagen_mobile,
		home_banner_link,
		home_banner_link_external,
		bootLoaded,
		areas,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appRedirect,
	appUIPopupLoginShow,
	appUIPopupTypeformShow,
	appUIErrorsShow,
	anunciosHomeInit,
	anunciosHomeGet,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
