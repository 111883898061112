import React from "react";
import * as styles from "./styles.module.scss";
import { Link } from "gatsby-plugin-intl";
import { ReactSVG } from "react-svg";
import { connect } from "react-redux";

const Grid = ({ image, title, text, link, s, linkClassName }) => {
	return (
		<Link to={link} className={`${styles.gridBox} ${linkClassName}`}>
			<img src={image} />
			<div className={styles.text}>
				<h4 className="prequel-font">{title}</h4>
				<p>
					{text}
					<ReactSVG src="/img/arrow.svg" wrapper="span" />
				</p>
			</div>
		</Link>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(Grid);
