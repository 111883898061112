import styled from "styled-components";
import { mediaQueryMax } from "../../../assets/styles/size";

export const ImageListWrapper = styled.section`
	padding: 100px 0;
	@media ${mediaQueryMax.xl} {
		padding: 75px 0;
	}
	@media ${mediaQueryMax.lg0} {
		padding: 50px 0;
	}
`;
export const ListWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-left: -20px;
	margin-right: -20px;
	@media ${mediaQueryMax.lg0} {
		flex-wrap: wrap;
		justify-content: center;
	}
`;
export const ListItem = styled.div`
	flex: 0 0 20%;
	max-width: 20%;
	padding-left: 20px;
	padding-right: 20px;
	@media ${mediaQueryMax.lg0} {
		flex: 0 0 33.3333%;
		max-width: 33.3333%;
		margin: 20px 0;
	}
	@media ${mediaQueryMax.xs2} {
		flex: 0 0 100%;
		max-width: 100%;
	}
	a {
		display: block;
		text-align: center;
		img {
			margin: auto;
		}
	}
`;
