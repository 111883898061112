import styled from "styled-components";
import { colors } from "../../../assets/styles/colors";
import { mediaQueryMax } from "../../../assets/styles/size";

export const SpecialBulletsWrapper = styled.section`
	padding: 80px 0 100px;
	@media ${mediaQueryMax.lg0} {
		padding: 45px 0 70px;
	}
`;

export const BulletsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	@media ${mediaQueryMax.lg0} {
		flex-direction: column;
	}
`;

export const BulletItems = styled.div`
	flex: 0 0 30%;
	max-width: 300px;
	padding: 0 10px;
	box-sizing: border-box;
	text-align: center;
	@media ${mediaQueryMax.lg0} {
		flex: 0 0 100%;
		max-width: 100%;
		margin-top: 30px;
		padding: 0;
		&:first-of-type {
			margin-top: 0;
		}
	}
	span.number {
		font-size: 80px;
		line-height: 1;
		color: ${colors.red};
		font-weight: 700;
		display: block;
		margin-bottom: 14px;
	}
	svg {
		margin: 0 auto 25px;
	}

	h4 {
		${(props) => props.upperCase && `text-transform: uppercase;`}
		margin: 0;
		font-size: 20px;
		line-height: 1.2;
		@media ${mediaQueryMax.lg0} {
			font-size: 24px;
		}
	}
	p {
		margin-top: 10px;
		@media ${mediaQueryMax.lg0} {
			margin-top: 15px;
		}
	}
`;
