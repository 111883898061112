import styled, { css } from "styled-components";
import { colors } from "../../../assets/styles/colors";
import { mediaQueryMax } from "../../../assets/styles/size";
import { GlobalInnerContent } from "../../../assets/styles/commonStyles";

export const ImageTwoColWrapper = styled.section`
	margin-bottom: 40px;
	${(props) =>
		props.full &&
		css`
			margin-bottom: 0;
			${GlobalInnerContent} {
				max-width: none;
				padding: 0;
				margin-bottom: 0;
			}
		`};
`;

export const TextCol = styled.div`
	background-color: ${colors.blue};
	padding: 4%;
	padding-right: 14%;
	color: ${colors.white};
	@media ${mediaQueryMax.lg3} {
		padding-right: 4%;
	}
	@media ${mediaQueryMax.lg0} {
		padding: 50px;
	}
	@media ${mediaQueryMax.sm} {
		padding: 30px;
	}
	h2 {
		margin-bottom: 40px;
		@media ${mediaQueryMax.lg0} {
			margin-bottom: 30px;
		}
	}
`;

export const Text = styled.div`
	color: ${colors.lightgray03};
	margin-bottom: 40px;
	font-size: 20px;
	line-height: 1.5;
	font-weight: 300;
	p {
	}
	ul,
	ol {
		padding-left: 26px;
		li {
			margin-top: 6px;
			&:first-child {
				margin-top: 0;
			}
		}
	}
	ol {
		list-style-type: decimal;
	}
`;

export const ImgCol = styled.div`
	position: relative;
	overflow: hidden;
	@media ${mediaQueryMax.lg0} {
		height: 300px !important;
	}
	figure {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		margin: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
		${(props) =>
			props.imgMobile &&
			css`
		img {
			&.desktop {
				display: block;
			}
			&.mobile {
				display: none;
			}
		}
		@media ${mediaQueryMax.lg0} {
			img {
				&.desktop {
					display: none;
				}
				&.mobile {
					display: block;
				}
			}
		}
	}
	`};
`;

export const TwoColWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	> div {
		flex: 0 0 50%;
		max-width: 50%;
		@media ${mediaQueryMax.lg0} {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	${TextCol} {
		${(props) => props.position === "right" && "padding-right: 4%"};
		order: ${(props) => (props.position === "left" ? "0" : "1")};
		@media ${mediaQueryMax.lg0} {
			order: 0;
		}
	}
	${ImgCol} {
		order: ${(props) => (props.position === "left" ? "1" : "0")};
		@media ${mediaQueryMax.lg0} {
			order: 1;
		}
	}
`;
