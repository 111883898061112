import React from "react";
import { ImageListWrapper, ListWrapper, ListItem } from "./styled";
import { GlobalInnerContent } from "../../../assets/styles/commonStyles";

const ImageList = ({ items }) => {
	return (
		<ImageListWrapper>
			<GlobalInnerContent>
				<ListWrapper>
					{items?.map((item, index) => {
						return (
							<ListItem key={index}>
								<a href={item.url} target="_blank" rel="noreferrer">
									<img src={item.img} alt="" />
								</a>
							</ListItem>
						);
					})}
				</ListWrapper>
			</GlobalInnerContent>
		</ImageListWrapper>
	);
};

export default ImageList;
