import React, { useRef } from "react";
import { SliderWrapper, Header, Arrows } from "./styled";
import { GlobalInnerContent } from "../../../assets/styles/commonStyles";
import { Link } from "gatsby";
import { ReactSVG } from "react-svg";
import Slider from "react-slick";
import Board from "../../Board";
import Lodge from "../../Lodge";
import { useIntl } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import Tools from "../../../classes/tools";

const SliderCustom = (props) => {
	const intl = useIntl();
	const { title, text, linkText, linkUrl, items, typeContent, bgColor, paddingTop, paddingBottom, s } = props;
	const sliderRef = useRef(null);

	const boardsSettings = {
		slidesToShow: typeContent === "board" ? 6 : 4,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		infinite: false,
		responsive: [
			{
				breakpoint: 1279,
				settings: {
					slidesToShow: typeContent === "board" ? 4 : 3,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					variableWidth: true,
				},
			},
		],
	};

	return (
		<SliderWrapper bgColor={bgColor} paddingTop={paddingTop} paddingBottom={paddingBottom} typeContent={typeContent}>
			<GlobalInnerContent>
				<Header>
					{text ? (
						<div>
							<h3>{Tools.convertString(title)}</h3>
							<p dangerouslySetInnerHTML={{ __html: text }} />
						</div>
					) : (
						<>
							<h3>
								{Tools.convertString(title)}
								{linkText && linkUrl && <ReactSVG src="/img/arrow.svg" wrapper="span" className="hide-lg" />}
							</h3>
							{linkText && linkUrl && (
								<Link to={linkUrl} className="moreBoards">
									{Tools.convertString(linkText)}
									<ReactSVG src="/img/arrow.svg" wrapper="span" />
								</Link>
							)}
						</>
					)}
				</Header>

				<Slider ref={sliderRef} {...boardsSettings}>
					{items?.map((item, index) => {
						if (typeContent === "board") {
							return (
								<Board
									key={index}
									image={item.imagenes && item.imagenes.length > 0 && item.imagenes[0].url}
									title={Tools.convertString(item.title)}
									rentalPrice={(item.operacion === 1 || item.operacion === 3) && `${item.precio_alquiler}€`}
									finalPrice={(item.operacion === 2 || item.operacion === 3) && `${item.precio_venta}€`}
									link={`/veranuncio/${item.id}`}
									buttonClassName="anuncios_tablas"
								/>
							);
						} else if (typeContent === "hotel") {
							return (
								<Lodge
									key={index}
									image={item.imagenes[0]?.url}
									title={Tools.convertString(item.intl.title[intl.locale])}
									text={Tools.convertString(item.intl.excerpt[intl.locale])}
									sea=""
									discount=""
									linkTo={`/hoteles/${item.name}`}
									linkText={Tools.convertString(s.slider?.saber_mas)}
								/>
							);
						} else if (typeContent === "lodge") {
							return (
								<Lodge
									key={index}
									image={item.image}
									title={Tools.convertString(item.title)}
									text={Tools.convertString(item.text)}
									sea={Tools.convertString(item.sea)}
									linkTo={item.linkTo}
									linkText={Tools.convertString(item.linkText)}
									linkClassName={item.linkClassName}
								/>
							);
						}
					})}
				</Slider>
				<Arrows>
					<button onClick={() => sliderRef?.current?.slickPrev()}>
						<span className="arrow left">
							<img srcSet="/img/slider-arrow-new.svg" />
						</span>
					</button>
					<button onClick={() => sliderRef?.current?.slickNext()}>
						<span className="arrow right">
							<img srcSet="/img/slider-arrow-new.svg" />
						</span>
					</button>
				</Arrows>
			</GlobalInnerContent>
		</SliderWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(SliderCustom);
