import styled from "styled-components";
import { colors } from "../../../assets/styles/colors";
import { sizes, mediaQueryMax } from "../../../assets/styles/size";
import { GlobalInnerContent } from "../../../assets/styles/commonStyles";

export const SliderWrapper = styled.section`
	${(props) => props.bg && `background-color: ${props.bg}`};
	${(props) => props.paddingTop && `padding-top: ${props.paddingTop}px`};
	padding-bottom: ${(props) => (props.paddingTop ? props.paddingTop : 80)}px;
	@media (max-width: 991px) {
		${(props) => props.paddingTop && `padding-top: ${parseInt(props.paddingTop / 1.5)}px`};
		padding-bottom: ${(props) => (props.paddingBottom ? parseInt(props.paddingBottom / 1.5) : 40)}px;
	}
	${GlobalInnerContent} {
		.slick-slider {
			margin: ${(props) => (props.typeContent === "board" ? "0 -10px" : "0 -15px")};
			@media (max-width: 991px) {
				margin: 0 -7.5px;
			}
		}
	}
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
	position: relative;
	@media ${mediaQueryMax.lg0} {
	}
	div {
		max-width: 40%;
		margin-bottom: 50px;
		@media (max-width: 991px) {
			margin-bottom: 30px;
		}
		h3 {
			margin-bottom: 15px;
		}
		p {
			margin: 0;
		}
	}
	h3 {
		font-size: 25px;
		line-height: 41px;
		font-weight: 500;
		margin: 0;
		@media ${mediaQueryMax.lg0} {
			display: flex;
			align-items: center;
			font-size: 22px;
			line-height: 24px;
		}

		svg {
			margin-left: 15px;
			margin-top: 3px;
			height: 15px;
			width: 9px;

			path {
				fill: ${colors.black01};
				stroke: ${colors.black01};
			}
		}
	}

	a {
		color: ${colors.red};
		font-size: 16px;
		font-weight: 500;
		display: flex;
		align-items: center;
		letter-spacing: 1px;
		@media ${mediaQueryMax.lg0} {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			text-indent: -9999px;
			color: transparent;
		}

		em {
			font-style: none;
			@media ${mediaQueryMax.lg0} {
				display: none;
			}
		}

		svg {
			margin-left: 15px;
			margin-top: 3px;
			height: 13px;
			width: 9px;
			@media ${mediaQueryMax.lg0} {
				display: none;
			}
		}
	}
`;

export const Arrows = styled.div`
	position: absolute;
	top: 47%;
	left: 38px;
	margin: 0;
	width: calc(100% - 38px - 38px);
	display: flex;
	justify-content: space-between;
	z-index: 1;
	@media (max-width: 991px) {
		display: none;
	}
	button {
		background: none;
		border: none;
		cursor: pointer;
		outline: none;
		padding: 0;
		.right {
			img {
				transform: scaleX(-1);
			}
		}
	}
`;
