import React, { useRef } from "react";
import { connect } from "react-redux";
import { TestimonialWrapper, TestimonialText } from "./styled";
import { GlobalInnerContent } from "../../../assets/styles/commonStyles";
import { sizesValues } from "../../../assets/styles/size";
import Slider from "react-slick";

const SliderTestimonials = ({ items }) => {
	const sliderRef = useRef(null);

	const sliderSettings = {
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		arrows: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		speed: 500,
		fade: true,
		cssEase: "linear",
		// responsive: [
		// 	{
		// 		breakpoint: sizesValues.lg0,
		// 		settings: {
		// 			adaptiveHeight: true,
		// 		},
		// 	},
		// ],
	};

	return (
		<TestimonialWrapper>
			<GlobalInnerContent>
				<Slider {...sliderSettings} ref={sliderRef}>
					{items.map((item, index) => (
						<TestimonialText key={index}>
							<span>{item.icon}</span>
							<h5>{item.name}</h5>
							<p>{item.text}</p>
						</TestimonialText>
					))}
				</Slider>
			</GlobalInnerContent>
		</TestimonialWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(SliderTestimonials);
