import React from "react";
import * as styles from "./styles.module.scss";
import { Link } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import Tools from "../../classes/tools";

const Board = ({ image, title, rentalPrice, finalPrice, link, s, buttonClassName }) => {
	return (
		<Link to={link} className={`${styles.boardItem} ${buttonClassName}`}>
			<div className={styles.wrapperImg}>
				<img src={image ? image : "/img/anuncio-sin-imagenes.png"} />
			</div>
			<div className={styles.boardDescription}>
				<h4 dangerouslySetInnerHTML={{ __html: title }} />
				{rentalPrice && (
					<p>
						{Tools.convertString(s.slider?.alquiler)}: {rentalPrice}
					</p>
				)}
				{finalPrice && (
					<p>
						{Tools.convertString(s.slider?.venta)}: {finalPrice}
					</p>
				)}
			</div>
		</Link>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(Board);
